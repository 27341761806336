
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600&display=swap');
$primary: #5d87ff;
$black: rgb(42, 53, 71);
$lightBlack: rgba(42, 53, 71, 0.8);
$light: #F9F9F9;
$lightLayer: rgba(0,0,0, 0.02);
$gray: rgba(0,0,0, 0.04);
$darkGray: rgba(0,0,0, 0.1);
$bprimary: rgb(13, 110, 253);
$primaryBackground: #F8F9FD;
$darkPrimary: #193676;
$primaryLayer: rgba(13,110,253, 0.3);


$black: rgb(42, 53, 71);
$blackLight: rgba(42, 53, 71, 0.8);
$primary: #5d87ff;
$primaryDark: #193676;
$primaryLight: #5d87ff;
$gray: rgba(0,0,0, 0.04);
$grayLight: #F9F9F9;
$grayDark: rgba(0,0,0, 0.1);
$green: #449D44;
$red: #D9534F;
$yellow: #F0AD4E;

/** Layers **/
$grayLayer: rgba(0,0,0, 0.02);
$primaryLayer: rgba(93, 135, 255, 0.1);
$primaryDarkLayer: rgba(93, 135, 255, 0.2);
$redLayer: rgba(217,83,79, 0.1);
$blackLayer: rgba(42, 53, 71, 0.2);


@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

html, body, * {
  font-family: 'Inter', sans-serif;
}
html, body, #root {
  height: 100%;
}

.loading {
  .loading-logo-image {
      max-width: 40px;
      border-radius: 8px;
      margin-top: 10px;
    }
  .dot-flashing {
    margin-left: 15px;
  }
}

.wd-sidebar-wrapper {
  width: 260px;
  min-width: 260px;
  height: 100%;
}

.login-logo-wrapper {

    text-align: center;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 14px;
    align-items: stretch;
    .login-logo-image {
      max-width: 50px;
      border-radius: 8px;
      margin-right: 10px;
    }
    h2 {
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 10px;
      color: $primary ;
      font-size: 2rem;
      font-weight: 500;
      span {
        margin-top: 2px;
      }
    }
  }

.wd-sidebar {
  background-color: $light;
  width: 260px;
  min-width: 260px;
  height: 100%;
  .nav {
    padding: 0 16px;
    padding-top: 20px;
    .nav-title {
      margin: 16px 0 8px;
      padding-left: 18px;
      text-transform: uppercase;
      font-size: .75rem;
      font-weight: 600;
      color: $lightBlack;
    }
  }
  .nav-logo-wrapper {
    background-color: $lightLayer;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding-top: 20px;
    padding-left: 36px;
    padding-bottom: 10px;
    align-items: stretch;
    .nav-logo-image {
      max-width: 34px;
      border-radius: 8px;
      margin-right: 10px;
    }
    h2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 10px;
      color: $primary ;
      font-size: 1.3rem;
      font-weight: 500;
      span {
        margin-top: 2px;
      }
    }
  }
  .nav-organization-selector {
    background-color: $lightLayer;
    padding: 8px 24px;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    select {
      border: none;
      background: transparent;
      width: 100%;
      max-width: 100%;
      font-size: 13px;
      padding: 6px 6px;
    }
  }
  .nav-user {
    margin: 8px;
    background-color: $lightLayer;
    border-radius: 8px;
    padding: 8px 20px;
    .nav-user-icon {
      background-color: $gray;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      font-size: 1.5rem;
      color: $lightBlack;
    }
    .nav-user-content {
      font-size: 13px;
      a {
        font-size: 12px;
        font-weight: 600;
        text-decoration: none;
        color: $black;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}



.nav-pills .nav-link {
    border-radius: 7px;
    height: 45px;
    padding: 6px 22px!important;
    margin-bottom: 2px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: 0;
    align-items: stretch;
    color: $black;
  &:hover {
    background-color: rgba(0,0,0, 0.03);
  }
  .nav-icon {
    margin-right: 14px;
    margin-left: 0!important;
    width: 20px;
    height: 20px;
    align-self: center;
    fill: #2a3547;
  }
  .content {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: center;
    flex: 1;
    font-size: 13px;
    line-height: 20px;
  }
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: $primary;
  &:hover {
    background-color: $primary;
  }
  .nav-icon {
    fill: white !important;
  }
}


.main-content {
  
  .main-header {
    height: 60px;
    
    ol {
      margin: 0px;
      a {
        text-decoration: none;
      }
    }
    .breadcrumb-item.active {
      font-weight: 600;
    }
  }

  table {
    box-shadow: #919eab4d 0 0 2px, #919eab1f 0 12px 24px -4px;
    border-radius: 7px;
    overflow: hidden;

    tbody tr th, tbody tr td {
      padding: 16px;
    }
    tbody tr th {
      font-weight: 400;
    }
    thead tr {
      height: 56px;
      font-weight: 600;
    }
    thead tr th, thead tr td {
      padding: 0 16px 0 16px;
      vertical-align: middle;
    }
    thead th:first-child {
      padding-left: 26px !important;
    }
    tbody th {
      padding-left: 26px !important;
    }
    .td-actions {
      white-space:nowrap

      .btn {
        margin-right: 10px;

      }
    }
  }
}



form {
  position: relative;
  label {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: .5rem;
  }
}

.card-header {
  .card-header-icon-wrapper {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 2px;
    border-radius: 4px;
    background-color: $primaryLayer;
    color: $darkPrimary;
  }
}

.card-trigger {
  position: relative;

  width: 100%;
  max-width: 400px;
  background-color: white;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border: 3px solid $primaryLayer;
  padding: 0px;
  .card-header {
    border-top-right-radius: 10px;
    border-bottom-color: $gray;
    color: $darkPrimary;
    .card-header-icon-wrapper {
      background-color: $primaryLayer;
      color: $darkPrimary;
    }
  }
  .label {
    background-color: $primaryLayer;
    font-size: 12px;
    font-weight: 600;
    color: $darkPrimary;
    text-transform: uppercase;
    padding: 2px 4px;
    position: absolute;
    left: -3px;
    top: -25px
  }
}

.connector {
  height: 20px;
  border: 2px solid $darkGray;
  width: 0px;
}
.interactions-builder-wrapper {
  background-color: $primaryBackground;
  border-radius: 7px;
  width: 100%;
}

.card-interaction {
  width: 100%;
  border: 2px solid $darkGray;
  max-width: 400px;
  .card-title {
    margin-bottom: 2rem;
  }
  textarea {
    min-height: 60px;
  }

  select.interaction-type {
    font-weight: 400;
    font-size: .8rem;
    color: white;
    background-color: $primary;
    border: none;
    border-radius: 6px;
    padding: 2px 5px;
  }
}

.text-bg-primary {
  background-color: $primary !important;
}

.btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: none;
    outline: none;
    line-height: inherit;
    user-select: none;
    -webkit-appearance: none;
    overflow: visible;
    vertical-align: middle;
    font-size: 13px;
    font-weight: 500;
    padding: 8px 18px;
    border-radius: 20px;
    min-height: 40px;
    .icon {
      font-size: 1.5rem;
      padding-right: 6px;
      margin-left: -4px;
    }
    &.btn-sm {
      border-radius: 7px;
      font-weight: 300;
      font-size: 13px;
    }
    &.btn-primary {
      background-color: $darkPrimary;
      border-color: $darkPrimary;
      &:hover {
        background-color: $darkGray;
        color: $darkPrimary;
      }
    }
}

.form-control {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $darkGray;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $darkGray;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $darkGray;
  }
}

.btn-rotate {
  &:hover {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
}

#flow-form {
  border: 2px solid $gray;
}

.form-controls {
  margin-bottom: 30px;
  right: 10px;
  top: -66px;
  z-index: 1000;
}


.bg-dotted {
  background: $light;
  background-image: radial-gradient($darkGray 1px, transparent 0);
  background-size: 34px 34px;
  background-position: 0px 0px;
}



.ua-main {
  .ua-main__header {
    min-height: 86px;
    h1 {
      font-size: 1.8rem;
      font-weight: 700;
    }
  }
}

.ua-main__schools {
  .ua-main__schools-index-logo {
    width: auto;
    height: 100%;
    max-height: 26px;
  }
}

.ua-datagrid {
  border: none;
  .InovuaReactDataGrid__column-header__content {
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 300;
    color: $black;
  }
  .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left {
    border: none;
  }
  .InovuaReactDataGrid__cell {
    border: none;
  }
  .InovuaReactDataGrid__row--odd {
    background-color: $grayLayer;
  }
  .InovuaReactDataGrid__header-wrapper {
    border: none;
  }
  .inovua-react-toolkit-text-input.inovua-react-toolkit-text-input--theme-default-light {
    background-color: $grayLight;
    border-radius: 3px;
    border: 1px solid transparent;
  }
  .inovua-react-toolkit-combo-box.inovua-react-toolkit-combo-box--theme-default-light {
    background-color: $grayLight;
    border-radius: 3px;
    border: 1px solid transparent;
  }
  .inovua-react-toolkit-numeric-input.inovua-react-toolkit-numeric-input--theme-default-light {
    background-color: $grayLight;
    border-radius: 3px;
    border: 1px solid transparent;
  }
}


.btn.btn--ua-backoffice {
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.btn--ua-primary-light {
  background-color: $primaryLayer;
  border: none;
  &:disabled {
    background-color: transparent;
    cursor: not-allowed;  
      cursor: -moz-not-allowed;
      cursor: -webkit-not-allowed;
      color: $grayDark !important;
  }
  &:hover {
    background-color: $primaryDarkLayer;
  }
}
.btn.btn--ua-light {
  background-color: transparent;
  border: none;
  small {
    line-height: 1px;
  }
  &:disabled {
    background-color: transparent;
    cursor: not-allowed;  
      cursor: -moz-not-allowed;
      cursor: -webkit-not-allowed;
      color: $grayDark !important;
  }
  &:hover,&.active {
    background-color: $primaryLayer;
  }
  &.text-danger:hover, &.text-danger.active {
    background-color: $redLayer;
  }
}
.ua--badge {
  font-size: 12px;
}

.ua--full-page {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}